<template>
<v-sheet class="setting-container">
  <div class="palnprint">
    <div class="pp_head">
      <span class="text-h5"> {{this.filter.task_start_date.replace(/(.*)-(.*)-(.*)/, "$1年$2月")}} 月计划表</span>
      <!-- <a button="" class="print" id="download" href="/Plan/500528195/Home/DownloadlanItems?toUserId=500528195&amp;groupId=9&amp;tagId=10&amp;planTableId=4da6533a-c566-44af-acba-21c095b90b3d">导出</a> 

<a button="" class="print" onclick="printme()" id="print_a">打印</a> -->
    </div>
    <table class="mark">
      <tbody>
        <tr>
          <td class="department">部门：<span>{{this.authUser.user_company_name}}</span></td>
          <td class="name">姓名：<span>{{this.authUser.employeeName}}</span></td>
          <td class="date">日期：<span>{{this.filter.task_start_date+' ~ '+ this.filter.task_end_date}}</span></td>
        </tr>
      </tbody>
    </table>
    <div class="pp_content">
      <v-data-table :headers="headers" :items="newList" disable-pagination class="elevation-0"></v-data-table>
    </div>
    <div class="leader">
      <span class="leader_title">上级审批意见</span>
      <span class="leader_date">签名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日
      </span>
    </div>
    <div class="pp_tips">
      <span class="person">
        <span>{{this.authUser.employeeName}}</span>于<span>{{Date()|chinaFormatDay}}</span> 打印此计划表
      </span>
      <span class="firm">{{this.authUser.user_company_name}}</span>
    </div>
  </div>
</v-sheet>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapActions
} from 'vuex';
export default {
  name: 'SettingPage',
  components: {},
  data() {

    return {
      filter: {},
      headers: [{
          text: '序号',
          align: 'center',
          sortable: true,
          divider: true,
          value: 'num',
          width: '80'
        },
        {
          text: '计划项名称',
          divider: true,
          value: 'task_name',
        },
        {
          text: '备注',
          // divider: true,
          sortable: false,
          value: ''
        },
      ],
    }
  },
  created() {
    this.filter = JSON.parse(this.$route.query.filter);
    this.getTasks(this.filter).then(res => {

    })
  },
  computed: {
    ...mapGetters('task', ['tasks']),
    ...mapGetters("auth", ["authUser"]),

    newList() {
      return this.tasks.map((item, index) => {
        item.num = index + 1;
        return item;
      });

    },
  },
  methods: {
    ...mapActions('task', ['getTasks', 'addTask']),
    ...mapActions("user", ['getTaskUsers']),
  },
}
</script>

<style>
.pp_content .v-data-footer {
  display: none !important;
}
</style><style scoped>
.setting-container {
  padding: 10px 20px;
}
.palnprint .pp_tips .person, .palnprint .pp_tips .firm {
    position: absolute;
    display: inline-block;
    margin-top: 5px;
}
.palnprint .pp_tips .person {
    left: 0;
}
.palnprint .pp_tips .firm {
    right: 0;
}
.palnprint {
  width: 555px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  font-size: 12px;
  font-family: Arial, "Microsoft YaHei", SimHei, SimSun;
}

.palnprint .pp_head {
  text-align: center;
  margin-top: 36px;
}

.palnprint .mark {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.palnprint .pp_content {
  margin-top: 24px;
  border: solid grey;
  border-width: 1px;
}

.palnprint .leader {
  height: 200px;
  position: relative;
  border: 1px solid #ddd;
  /* border-top: 0; */
}

.palnprint .leader .leader_title {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background: #f7f7f7;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-indent: 10px;
  font-weight: 400;
  font-size: 12px;
}

.palnprint .leader .leader_date {
  display: inline-block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-right: 5px;
}

.palnprint .pp_tips {
  position: relative;
  margin-top: 30px;
  color: #aaa;
  font-size: 12px;
  border-top: 1px solid #ddd;
  margin-top: 30px;
  font-family: SimSun, "Microsoft YaHei", SimHei;
}

.palnprint .middle_table,
.palnprint .foot_table {
  width: 100%;
  border-collapse: collapse;
}
</style>
